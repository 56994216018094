import {animate, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, EMPTY, fromEvent, interval, Observable, of, ReplaySubject, Subject, timer} from 'rxjs';
import {delay, distinctUntilChanged, filter, first, map, skipWhile, switchMap, takeUntil, tap} from 'rxjs/operators';

import {castExists, checkExhaustive} from 'asserts/asserts';
import {ClipLocalStorage, StorageChanges} from 'models/storage.model';
import {RenditionService} from 'services/rendition/rendition.service';
import {VideoProtocolService} from 'services/video-protocol.service';

import {mapOnError} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {FirebasePerformanceService, Trace, TraceName} from '../firebase/firebase_performance_service';
import {Asset, AssetService, AssetState, ClipMarking} from '../services/asset_service';
import {DeviceInputService} from '../services/device_input_service';
import {RenditionVersion, RenditionVersionEnum} from '../services/ias_types';
import {LiveAssetManifestInfo, ManifestService} from '../services/manifest.service';
import {PlayerDetailsService} from '../services/player_details_service';
import {FullscreenElementPriorityPresets, PlayerFullscreenService} from '../services/player_fullscreen_service';
import {PreferencesService} from '../services/preferences_service';
import {ResizeObserverService} from '../services/resize_observer_service';
import {KeywordResult, QuerySegment} from '../services/search_service';
import {HomeView, ShortcutEvent, ShortcutEvents} from '../services/state_service';
import {ClipbinStorageService} from '../services/storage/clipbin_sharing_storage.service';
import {TimezoneService} from '../services/timezone_service';
import {UtilsService} from '../services/utils_service';

import {OverlayControlEvent, OverlayControlEventType, PlayerOverlayControls} from './player_overlay_controls';
import {ShakaPlayer} from './shaka_player';
import {TimeFormatEnum} from './time_format_selection';
import {CutDownSegments, Timeline} from './timeline';
import {BufferSegment, VideoPlayer} from './video_player';

/**
 * Playback types
 * The values will be used as icon names
 */
enum PlaybackIcon {
  FAST_REWIND = 'fast_rewind',
  FAST_FORWARD = 'fast_forward',
}

/** Emitted when the player time or paused state changes. */
export interface PlayerUpdate {
  time?: number;
  paused: boolean;
}

/**
 * Framerate of the high-quality video used for playback set on the backend.
 */
const MAIN_PREVIEW_FPS = 30;

const FRAME_DURATION_SEC = 1 / MAIN_PREVIEW_FPS;

/** Duration from beginning of fade-in to beginning of fade-out transitions. */
const PLAY_RATE_OVERLAY_DURATION_MS = 500;

/** Available playback speed rate from keyboard shortcuts. */
const KEYBOARD_PLAY_RATES =
    [-32, -16, -8, -4, -2, -1, 1, 2, 4, 8, 16, 32] as const;

/** How many sprites per seconds to fetch during speed-seeking. */
const SPEED_SEEKING_FPS = 10;

/**
 * How many seconds before the live edge offset do we allow the player to lag
 * before it is forced to jump at edge time (while in live-edge mode).
 */
const MAX_DISTANCE_FROM_EDGE_SEC = 5;

/**
 * How often to check whether the current time is too far from the edge time
 * (as defined by `MAX_DISTANCE_FROM_EDGE_SEC`), in milliseconds.
 */
const EDGE_CHECK_INTERVAL_MS = 2000;

/**
 * If playback speed is above this rate, speed-seeking will be enabled.
 */
const REGULAR_SEEKING_HIGHEST_RATE = 4;

/** Player controls width below which some items are hidden. */
const SMALL_CONTROLS_BREAKPOINT = 750;
const SMALLER_CONTROLS_BREAKPOINT = 500;
const SMALLEST_CONTROLS_BREAKPOINT = 400;
const CLIP_SELECTION_CONTROLS_BREAKPOINT = 500;
const REPLAY_FORWARD_CONTROLS_BREAKPOINT = 600;
const REPLAY_FORWARD_CONTROLS_DISABLED_ADD_CLIP_BREAKPOINT = 400;
const CLIP_DURATION_CONTROLS_BREAKPOINT = 700;
const TITLED_ADD_CLIP_CONTROL_BREAKPOINT = 850;

// Constants below are calculated from the above settings
const KEYBOARD_RATE_PLUS_1_INDEX = KEYBOARD_PLAY_RATES.indexOf(1);
const KEYBOARD_RATE_MINUS_1_INDEX = KEYBOARD_PLAY_RATES.indexOf(-1);

/**
 * Full video player with seekable timeline, playback controls with audio track
 * and speed selection, the ability to seek on hover.
 */
@Component({
  selector: 'mam-player-with-controls',
  providers: [PlayerDetailsService],
  templateUrl: './player_with_controls.ng.html',
  styleUrls: ['./player_with_controls.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger(
      'fade',
      [
        transition(
            ':enter', [style({opacity: 0}), animate(200, style({opacity: 1}))]),
        transition(
            ':leave', [style({opacity: 1}), animate(100, style({opacity: 0}))])
      ])]
})
export class PlayerWithControls implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild(ShakaPlayer) shakaPlayer!: ShakaPlayer;
  @ViewChild(VideoPlayer) videoSeek!: VideoPlayer;
  @ViewChild(PlayerOverlayControls) overlayControls!: PlayerOverlayControls;
  @ViewChild(Timeline) timeline?: Timeline;
  @ViewChild('trackCropper') trackCropper?: ElementRef<HTMLElement>;
  @ViewChild('controlsBlock') controlsBlock?: ElementRef;
  @ViewChild('playerArea') playerArea !: ElementRef<HTMLElement>;
  @ViewChild('timelineArea') timelineArea !: ElementRef<HTMLElement>;

  /** Current asset (original or clip) being viewed in the player.  */
  @Input()
  get asset(): Asset|undefined {
    return this.assetInternal;
  }
  set asset(asset: Asset|undefined) {
    this.asset$.next(asset);
  }

  /** Query text from the user search input */
  @Input() userQuery?: string;

  @Input() querySegment?: QuerySegment;

  @Input() disabledMarking = false;

  @Input() disabledAddClip = false;

  /** Extra chips to be rendered in the timeline. */
  @Input() keywordResults?: KeywordResult[];

  /**
   * The chip selected in the search bar (Insights panel). Passed to the
   * timeline to highlight relevant segments above the timeline.
   */
  @Input() selectedKeyword?: string;

  @Input() cutDownMode = false;

  /** Compact mode that removes extra margins around the video. */
  @Input() compact = false;

  /** Indicator to show/hide overlay controls (replay, pause/play, forward). */
  @Input() showOverlayControls = false;

  /** Indicator to show/hide fullscreen desktop controls (only desktop). */
  @Input() showFullScreenDesktopControls = false;

  /** Whether keyboard shortcuts are disabled. */
  @Input() disabledShortcuts = false;

  /**
   * Optional callback used to sign private media URLs. The same can be done by
   * calling the `addSegmentSigningHook` method.
   */
  @Input() signer?: (rawUrls: string[]) => Observable<Array<string|null>>;

  /**
   * How long to wait before buffering the current seek-playhead position, in
   * ms. `0` to disable and only seek when moving the main-playhead.
   */
  @Input() seekCursorDebounceMs = 500;

  @Input()
  get defaultTrackIndex() {
    return this.shakaPlayer.defaultTrackIndex;
  }
  set defaultTrackIndex(index: number) {
    this.shakaPlayer.defaultTrackIndex = index;
  }

  @Input() sendTrackIndex: boolean = false;

  @Output() readonly addClipClick = new EventEmitter<ClipMarking | undefined>();

  @Output() readonly confirmCutDown = new EventEmitter<ClipMarking>();

  @Output() readonly playerError = new EventEmitter<string>();

  @Output() readonly playerUpdate = new EventEmitter<PlayerUpdate>();

  @Output() readonly markingChanged = new EventEmitter<ClipMarking|undefined>();

  @Output() readonly shortcutTriggered = new EventEmitter<ShortcutEvent>();

  bufferSegments: BufferSegment[] = [];

  isClipCreation = false;

  get enabledMarking() {
    return this.deviceInputType.isInputViaTouch() && !this.cutDownMode
      ? !this.disabledMarking && this.isClipCreation
      : !this.disabledMarking;
  }

  get showClipCreation() {
    return this.deviceInputType.isInputViaTouch() && !this.disabledMarking && !this.cutDownMode;
  }
  /**
   * Time in seconds of main playhead. Main video may have a different time
   * while we are seeking through a buffered portion of the video (the playhead
   * is fixed).
   */
  get mainPlayheadTime() {
    return this.mainPlayheadTimeInternal;
  }
  set mainPlayheadTime(time: number|undefined) {
    if (this.mainPlayheadTimeInternal === time) return;

    this.mainPlayheadTimeInternal = time;
    this.emitUpdate();
  }

  /**
   * Current clip or cut-down area with times in seconds relative to the
   * original full VoD or beginning of the live stream.
   */
  clipMarking?: ClipMarking;

  volumeSliderHidden = true;

  /** Whether the current asset is ready to play. */
  isAssetLoaded = false;

  /**
   * List of clips (start and end times) belonging to the current live stream,
   * that will be highlighted in the timeline. The truthiness of this list, even
   * if empty, indicates that we toggled the cut-down mode, which changes the UI
   * buttons and colors.
   */
  cutDownSegments?: CutDownSegments;

  /**
   * Playback speed can be changed through the dropdown or keys 'jkl'. The range
   * of 'jkl' changes based on what the user chooses in the dropdown.
   */
  playback = {
    /**
     * Available playback rates from keyboard shortcuts. -1/+1 may be changed
     * to `selectedSpeed` as a base speed.
     */
    keyboardRange: [...KEYBOARD_PLAY_RATES] as number[],
    dropdownRange: [.25, .5, .75, 1, 1.25, 1.5, 1.75, 2],
    /** Indicates the speed the user chooses as default in the dropdown */
    selectedSpeed: 1,
    /** Indicates where on the "scale" the user is in the keyboard range */
    keyboardIndex: KEYBOARD_RATE_PLUS_1_INDEX,
    isFlashVisible: false,
    flashTimer: 0,
    /** Seek intervals range. Used for replay/forward video. */
    seekIntervalsRange: [5, 10, 30],
    /** Default (or selected by user) seek interval.  */
    selectedSeekInterval: 10,
  };

  /**
   * When defined, the main shaka player is hidden and seek-on-hover is
   * rendered. For VoD videos, a different video player is used to seek at this
   * time. For live streams, spritesheets are used by setting this time to a
   * dynamic thumbnail.
   */
  seekOnHoverTime?: number;

  /** Hides some controls when the player width is too low. */
  smallControls = false;
  smallerControls = false;
  smallestControls = false;
  clipSelectionControls = false;
  clipDurationControls = false;
  replayForwardControls = false;
  titledAddClipControl = false;

  isIos = this.playerFullscreenService.isIos;
  isAndroid = this.playerFullscreenService.isAndroid;
  isSmallScreen$ = this.playerFullscreenService.isSmallScreen$;
  isLandscape$ = this.playerFullscreenService.isLandscape$;

  /**
   * Whether currently playing the live stream at its most recent time (edge)
   * and not in DVR (playback in the past).
   */
  isLiveEdgeActive = false;

  /** Keep the selected format. */
  selectedTimeFormat: TimeFormatEnum = TimeFormatEnum.DURATION;

  readonly AnalyticsEventType = AnalyticsEventType;

  private readonly asset$ = new ReplaySubject<Asset|undefined>(1);

  private assetInternal?: Asset;

  private mainPlayheadTimeInternal?: number;

  private canPlayVideo$ = new ReplaySubject<boolean>(1);

  /** Flag to show additional buttons/improvements. */
  readonly showAdditionalButtonsFF =
      this.featureService.featureOn('player-controls-improvements');

  /** Flag to show fullscreen buttons for desktop mode. */
  readonly showFullScreenButtonsDesktopFF =
      this.featureService.featureOn('show-fullscreen-buttons-desktop');

  get tracks(): shaka.extern.Track[] {
    return this.shakaPlayer?.tracks || [];
  }

  get selectedTrackIndex() {
    return this.tracks.findIndex(track => track.active);
  }

  /**
   * Calculates the line-height of the `trackCropper` element. If found, it
   * is saved for future retrieval without re-computation.
   */
  get trackCropperLineHeight() {
    if (this.trackCropperLineHeightInternal > 0) {
      return this.trackCropperLineHeightInternal;
    }

    if (!this.trackCropper?.nativeElement) return 0;

    const lineHeight = getComputedStyle(this.trackCropper?.nativeElement)
                           .getPropertyValue('line-height');
    this.trackCropperLineHeightInternal =
        (!/\dpx/.test(lineHeight)) ? 0 : Number(lineHeight.replace('px', ''));

    return this.trackCropperLineHeightInternal;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly errorService: ErrorService,
    private readonly performanceService: FirebasePerformanceService,
    private readonly analyticsService: FirebaseAnalyticsService,
    private readonly deviceInputType: DeviceInputService,
    private readonly manifestService: ManifestService,
    private readonly resizeObserver: ResizeObserverService,
    private readonly utils: UtilsService,
    private readonly preferences: PreferencesService,
    private readonly assetService: AssetService,
    private readonly timezone: TimezoneService,
    private readonly renditionService: RenditionService,
    private readonly videoProtocolService: VideoProtocolService,
    readonly playerFullscreenService: PlayerFullscreenService,
    public readonly playerDetailsService: PlayerDetailsService,
    private readonly featureService: FeatureFlagService,
    private readonly clipbinStorageService: ClipbinStorageService,
    private elementRef: ElementRef
  ) {
    // Keep the current manifest info in memory.
    this.liveManifestInfo$.pipe(takeUntil(this.destroyed$)).subscribe(manifestInfo => {
      this.liveManifestInfo = manifestInfo;
    });

    // When playing a live stream at edge (red timeline), periodically check
    // the lag between the current time and the edge time. If it passes the
    // threshold, jump back to the edge time.
    this.manifestChanged$
      .pipe(
        switchMap(() => this.onNextCanPlay()),
        switchMap(() => {
          return this.isLiveActive() ? this.utils.timer(EDGE_CHECK_INTERVAL_MS) : EMPTY;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        if (!this.isLiveEdgeActive || this.mainPlayheadTime == null) return;
        if (this.mainPlayheadTime < this.getLiveEdgeTime() - MAX_DISTANCE_FROM_EDGE_SEC) {
          this.shakaPlayer.seek(this.getLiveEdgeTime());
        }
      });

    this.playerFullscreenService.isFullScreen$.pipe(takeUntil(this.destroyed$)).subscribe(isFullScreen => {
      this.isFullScreen = isFullScreen;
      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    this.observeAssetChanged();

    this.observeAssetLoaded();

    this.initiateProxyLoading();
  }

  ngAfterViewInit() {

    fromEvent(this.shakaPlayer.video, 'canplay').pipe(takeUntil(this.destroyed$)).subscribe(()=>{
        this.canPlayVideo$.next(true);
    });

    if (this.signer) {
      this.addSegmentSigningHook(this.signer);
    }

    // When playback is fast and hits an unbuffered area, we switch to a
    // fast-seeking mode that uses thumbnails instead of video.
    this.addSpeedSeekingHook();

    // Consider page loaded when the first frame of the main video is shown.
    this.onNextCanPlay().pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.performanceTrace?.stop({
        attributes: {
          assetDuration: castExists(this.asset).duration,
          videoDuration: this.shakaPlayer.duration,
        },
      });
    });

    // Hide some controls when they wouldn't fit entirely.
    this.resizeObserver.observe(castExists(this.controlsBlock).nativeElement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(controlsBlockRect => {
          this.smallControls = controlsBlockRect.width < SMALL_CONTROLS_BREAKPOINT;
          this.smallerControls = controlsBlockRect.width < SMALLER_CONTROLS_BREAKPOINT;
          this.smallestControls = controlsBlockRect.width < SMALLEST_CONTROLS_BREAKPOINT;
          this.clipSelectionControls = controlsBlockRect.width < CLIP_SELECTION_CONTROLS_BREAKPOINT;
          this.clipDurationControls = controlsBlockRect.width < CLIP_DURATION_CONTROLS_BREAKPOINT;
          this.replayForwardControls = controlsBlockRect.width < (this.disabledAddClip ?
              REPLAY_FORWARD_CONTROLS_DISABLED_ADD_CLIP_BREAKPOINT :
              REPLAY_FORWARD_CONTROLS_BREAKPOINT);
          this.titledAddClipControl = controlsBlockRect.width < TITLED_ADD_CLIP_CONTROL_BREAKPOINT;
        });

    if (!this.cutDownMode) {
      this.playerFullscreenService.registerElement(this.elementRef.nativeElement, FullscreenElementPriorityPresets.player);
    }

    [this.playerArea, this.timelineArea]
    .forEach((elementRef => {
      elementRef.nativeElement.addEventListener('touchstart', () => {
        this.playerDetailsService.setDetails(true);
      });
      elementRef.nativeElement.addEventListener('touchend', () => {
        this.playerDetailsService.temporarilyEnableDetails();
      });
    }));


    this.playerArea.nativeElement.addEventListener('mousemove', () => {
      this.playerDetailsService.temporarilyEnableDetails();
    });
    this.controlsBlock?.nativeElement.addEventListener('mouseenter', () => {
      this.playerDetailsService.setDetails(true);
    });
    this.controlsBlock?.nativeElement.addEventListener('mouseleave', () => {
      this.playerDetailsService.temporarilyEnableDetails();
    });
  }

  @HostBinding('class.is-full-screen') isFullScreen: boolean = false;

  isClipBinSharedVideo() {
    const currentPath = window.location.pathname;
    return currentPath.includes('shared-clipbin');
  }

  isAllowedShortcutKeysClipBin(key: string): boolean {
    const allowedKeys = ['j', 'k', 'l', ' '];
    return allowedKeys.includes(key);
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.disabledShortcuts) return;

    const key = this.utils.getKeyboardShortcut(event);
    if (!key) return;

    if (this.isClipBinSharedVideo() && !this.isAllowedShortcutKeysClipBin(key)) return;

    switch (key) {
      case ' ':
        // Prevent buttons on the page to get clicked again.
        event.preventDefault();
        this.togglePlayPause();
        break;

      case 'k':
        this.pause();
        break;

      case 'shift+arrowleft':
        this.movePlayheadSeconds(-10);
        break;

      case 'arrowleft':
      case ',':
        this.moveByFrameCount(-1);
        break;

      case 'shift+arrowright':
        this.movePlayheadSeconds(+10);
        break;

      case 'arrowright':
      case '.':
        this.moveByFrameCount(+1);
        break;

      case 'c':
        event.preventDefault();
        this.onAddClipClicked();
        break;

      case 'j':
      case '<':
        this.nextOrPreviousKeyboardRate(-1);
        break;

      case 'l':
      case '>':
        this.nextOrPreviousKeyboardRate(1);
        break;

      case '0':
        this.movePlayheadAndSeek(this.getTimelineStart());
        break;

      case '1':
        this.movePlayheadAndSeekToRatio(0.1);
        break;

      case '2':
        this.movePlayheadAndSeekToRatio(0.2);
        break;

      case '3':
        this.movePlayheadAndSeekToRatio(0.3);
        break;

      case '4':
        this.movePlayheadAndSeekToRatio(0.4);
        break;

      case '5':
        this.movePlayheadAndSeekToRatio(0.5);
        break;

      case '6':
        this.movePlayheadAndSeekToRatio(0.6);
        break;

      case '7':
        this.movePlayheadAndSeekToRatio(0.7);
        break;

      case '8':
        this.movePlayheadAndSeekToRatio(0.8);
        break;

      case '9':
        this.movePlayheadAndSeekToRatio(0.9);
        break;

      case 'escape':
        if (this.isFullScreen) {
          this.toggleFullScreen();
        }
        break;

      case 'r':
        this.shortcutTriggered.emit({
          key: 'r',
          intent: ShortcutEvents.SHOW_SOURCE_ASSET,
          targetView: HomeView.DETAILS,
          targetTab: 'clipbins'
        });
        break;

      default:
        return;
    }

    this.analyticsService.logEvent('Keyboard shortcut for Player', {
      resource: this.asset?.name,
      string1: event.key,
      string2: event.code,
      eventType: AnalyticsEventType.KEY_DOWN,
    });
  }

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.controlsBlock?.nativeElement.contains(event.target)) {
      this.volumeSliderHidden = true;
    }
  }

  getTimelineStart() {
    return this.asset?.startTime || 0;
  }

  getTimelineEnd() {
    return this.getTimelineStart() + this.getTimelineDuration();
  }

  /** Duration of the current clip or asset to be used by the timeline. */
  getTimelineDuration() {
    if (!this.asset) return 0;

    // Case of full live assets where duration needs to be looked up in the
    // manifest.
    if (this.isLiveWithoutDuration(this.asset)) {
      // If the event is currently streaming, the current duration is
      // also the edge time, since it is relative to the beginning of the
      // stream.
      if (this.isLiveActive()) {
        // When paused, we do not want the timeline to keep growing.
        return !this.shakaPlayer.paused || !this.edgeTimeBeforePause ? this.getLiveEdgeTime() : this.edgeTimeBeforePause;
      }

      // For a live event not at live edge, use the static manifest
      // information.
      else {
        return this.liveManifestInfo?.durationInSeconds || 0;
      }
    }

    // In case of a VoD, we take the minimum between the asset duration as
    // provided by metadata and the actual duration available from the video
    // file, as it may be slightly shorter than what the metadata says and not
    // playable further. This also handles clips (VoD or Live) since the
    // asset duration will be lower than the actual video duration. In case of a
    // shared link, the duration is unknown from metadata, so we only rely on
    // the actual duration.
    return Math.min(
        this.shakaPlayer?.duration || Number.POSITIVE_INFINITY,
        this.asset.duration || Number.POSITIVE_INFINITY);
  }

  toggleClipCreation() {
    this.isClipCreation = !this.isClipCreation;
    this.cdr.markForCheck;
  }

  async toggleFullScreen():  Promise<void> {
    this.playerFullscreenService.toggleFullScreenMode();
  }

  openNativeFullScreen() {
    this.playerFullscreenService.openNativeFullScreen();
  }

  /**
   * Toggles the "playing" or "paused" video status, unless the action if forced
   * - `true`: will play the video
   * - `false`: will pause the video
   */
  async togglePlayPause(force?: boolean): Promise<void> {
    // Whenever manual play/pause is done, stop live-edge mode.
    this.isLiveEdgeActive = false;
    this.playback.isFlashVisible = false;

    // `this.isSpeedSeeking` may be reset to `false` during the call to
    // `setPlaybackSpeed`, so we save the current value here.
    const wasSpeedSeeking = this.isSpeedSeeking;

    await this.setPlaybackSpeed(
        /* newRate= */ this.playback.selectedSpeed,
        /* showOverlay= */ false,
        /* resetKeyboardScale= */ false);

    // If not forced, will play the video if it was paused and vice versa.
    const shouldPlay = (force != null) ? force : this.shakaPlayer.paused;

    // If we resumed playback while having the seeker cursor over the
    // timeline, seek to this frame an play from there.
    if (shouldPlay && !wasSpeedSeeking) {
      if (this.seekOnHoverTime != null) {
        await this.shakaPlayer.seek(this.seekOnHoverTime);
        this.seekOnHoverTime = undefined;
        this.cdr.detectChanges();
      }
      await this.shakaPlayer.play();
    } else {
      this.shakaPlayer.pause();
    }

    this.emitUpdate();
  }

  async play() {
    if (this.isPaused()) {
      await this.togglePlayPause(true);
    }
  }

  pause() {
    // Pause only if we were playing, which can be either with shaka player
    // of with the thumbnail player in case of speed-seeking.
    if (!this.isPaused()) {
      this.togglePlayPause(false);
    }
  }

  /**
   * Whether the asset is a live stream currently being broadcast (even though
   * the UI may be in DVR mode and playing a time in the past). Returns `false`
   * for clips of any type.
   */
  isLiveActive(): boolean {
    if (!this.asset || this.asset.original) return false;
    return this.asset.isLive && this.shakaPlayer.isManifestLive();
  }

  /**
   * Current time in seconds, relative to the start of the current asset or
   * clip, or beginning of a live stream.
   */
  getElapsed(asset?: Asset): number|undefined {
    if (!asset || !this.isAssetLoaded || this.mainPlayheadTime == null) {
      return undefined;
    }

    let elapsed = this.mainPlayheadTime - asset.startTime;
    // Trunc `elapsed` to the millisecond.
    elapsed = Math.trunc(elapsed * 1000) / 1000;
    // Report negative play time below -10ms, above that is considered 0 and may
    // be the result of a rounding issue.
    if (elapsed < -0.01 && !this.negativePlaybackReported) {
      this.errorService.handle(
          `Got a negative playhead time (${elapsed}) from ${asset.name} [${
              this.mainPlayheadTime}, ${asset.startTime}]`);
      this.negativePlaybackReported = true;
    }
    return Math.max(0, elapsed);
  }

  changeAudioTrack(track: shaka.extern.Track) {
    this.shakaPlayer.changeAudioTrack(track);
  }

  /**
   * Called when the selected audio track is changed on Shaka player. This is debounced such
   * that it will only be called when the user is done selecting a track.
   * This is used to store the selected audio track index for a given asset
   * in local storage.
   */
  onShakaTrackChange(trackIndex: StorageChanges<number>) {
    if (trackIndex.previous === trackIndex.current) return;

    // Store the default track index in local storage
    const apiDefaultTrack = this.clipbinStorageService.clipsList.find(clip => clip.name === this.asset?.name)?.audioTrack.default;

    // Remove the asset from local storage if the API default track was set
    if (this.asset?.name && apiDefaultTrack !== undefined && apiDefaultTrack === trackIndex.current) {
      this.clipbinStorageService.removeClip(this.asset?.name as string);
      return;
    }

    // Store the selected audio track index in local storage
    if (!this.sendTrackIndex) return;
    const clipToStorage: ClipLocalStorage = {
      name: this.asset?.name || '',
      title: this.asset?.title || '',
      audioTrack: {
        previous: trackIndex.previous,
        current: trackIndex.current,
        default: apiDefaultTrack ?? trackIndex.previous,
      },
    };

    this.clipbinStorageService.updateClip(clipToStorage);
  }

  /**
   * Retrieves the last selected audio track index from local storage and returns it.
   * This is only called if `sendTrackIndex` is `true`, and after the asset has
   * been loaded.
   */
  retrieveTrackFromStore(): number | undefined {
    if (!this.sendTrackIndex) return;
    const clip = this.clipbinStorageService.getClip(this.asset?.name || '');
    return clip?.audioTrack.current;
  }

  /** Converts video source buffer to timeline visible buffer segments. */
  onBufferUpdate(segments: BufferSegment[]): void {
    if (!this.asset) {
      this.bufferSegments = [];
      return;
    }

    const offset = this.asset.startTime;
    this.bufferSegments = segments.map(s => {
      return {
        // Offset the actual buffer to be relative to the current clip.
        startTime: s.startTime - offset,
        endTime: s.endTime - offset,
      };
    });
  }

  /** Emitted by the video player continuously while playing, or on seek. */
  async onTimeUpdate(): Promise<void> {
    // Do not update main playhead while we seek-on-hover, or before the first
    // segment has been loaded (so the playhead position could be determined).
    const seekingTimelineWhilePaused =
        this.isSeekingTimeline && this.shakaPlayer.paused;
    if (!this.isAssetLoaded || seekingTimelineWhilePaused) return;

    this.refreshMainPlayhead();

    // Nothing else to do while video is paused.
    if (this.shakaPlayer.paused) return;

    // While playing at live edge, remember the edge time. When we will seek
    // away from edge time (DVR playback), player.seekRange will continue
    // growing with the manifest, but we want to the timeline to keep a fixed
    // duration, so we remember the last edge time before pausing instead.
    if (this.isLiveActive()) {
      this.edgeTimeBeforePause = this.getLiveEdgeTime();
      return;
    }

    // When playhead reaches the end of the current asset, clip, or inactive
    // live stream, pause playback and move playhead one millisecond behind to
    // avoid an infinite loop.
    const end = this.getTimelineEnd();
    if (this.mainPlayheadTime && this.mainPlayheadTime > end) {
      await this.movePlayheadAndSeek(end - 0.001);
    }
  }

  async onClickTimeline(timelineTime: number): Promise<void> {
    // Convert timeline time (relative to current clip) to source video time.
    const time = timelineTime + this.getTimelineStart();
    await this.movePlayheadAndSeek(time);
    // In case we seeked to an area that does not have segments (which should
    // not happen when using growing manifests), the video may have seeked to
    // the earliest available segment instead of `time`, so we update the
    // playhead position to reflect this in the timeline.
    this.refreshMainPlayhead();
    this.cdr.markForCheck();
  }

  onSeekTimeline(timelineTime?: number): void {
    clearTimeout(this.bufferFromSeekDebouncer);

    // Ignore seek events while video plays.
    if (!this.isPaused()) return;

    this.isSeekingTimeline = timelineTime != null;

    if (timelineTime == null) {
      // Moused out from the timeline, seek back to the main playhead time or
      // reset to the start.
      this.seekOnHoverTime = undefined;
      this.shakaPlayer.seek(this.mainPlayheadTime || 0);
      return;
    }

    const time = timelineTime + this.getTimelineStart();

    // Main video is buffered, seek through it.
    if (this.shakaPlayer.isBuffered(time)) {
      this.seekOnHoverTime = undefined;
      this.shakaPlayer.fastSeek(time);
    }
    // Otherwise, use the seek lighter rendition.
    else {
      this.unbufferedSeek(time);
    }
  }

  clearMarking() {
    this.timeline?.clearMarking();
  }

  onShortcutEvent(event: ShortcutEvent) {
    this.shortcutTriggered.emit(event);
  }

  onMarking(clipMarking?: {markIn: number; markOut: number}) {
    if (!clipMarking) {
      this.clipMarking = undefined;
      this.markingChanged.emit(this.clipMarking);
      return;
    }

    if (!this.isPaused()) {
      this.togglePlayPause(true);
    }

    // Add current asset start to the timeline-relative marking event to make
    // them absolute for the current video (in case we are clipping from a
    // clip).
    this.clipMarking = {
      markIn: clipMarking.markIn + (this.asset?.startTime || 0),
      markOut: clipMarking.markOut + (this.asset?.startTime || 0),
    };

    this.markingChanged.emit(this.clipMarking);
  }

  onAddClipClicked() {
    if (this.disabledAddClip) return;
    this.addClipClick.emit(this.clipMarking);
  }

  // Sign GCS segment URLs before they are requested.
  addSegmentSigningHook(
      signer: (rawUrls: string[]) => Observable<Array<string|null>>): void {
    this.shakaPlayer.addSegmentSigningHook(signer);
  }

  getVolumeFromStorage(): number {
    return Number(this.preferences.load('user_volume') || '1');
  }

  getVolume(): number {
    if (!this.shakaPlayer || this.shakaPlayer.muted) return 0;
    return this.shakaPlayer.volume;
  }

  setVolume(newVolume: number, sliding = false): void {
    // Only log and save volume if it was manually set by the user. Ignore
    // intermediate events while the cursor is being dragged.
    if (!sliding) {
      this.analyticsService.logEvent('Volume slider', {
        eventType: AnalyticsEventType.SAVE_PREFERENCE,
        number1: newVolume,
      });

      this.preferences.save('user_volume', String(newVolume));
    }

    this.shakaPlayer.changeVolume(newVolume);
  }

  setVolumeSliderHidden(hideSlider: boolean): void {
    this.volumeSliderHidden = hideSlider;
  }

  async onLiveIndicatorClicked() {
    // If we were not at the edge time, seek to it and start playing.
    if (!this.isLiveEdgeActive) {
      // Clear any existing clip marking.
      this.timeline?.clearMarking();
      await this.shakaPlayer.seek(this.getLiveEdgeTime());
      await this.play();
      this.isLiveEdgeActive = true;
    }
    // Otherwise pause the video, which enters DVR mode.
    else {
      this.pause();
    }
  }

  /**
   * Moves the playhead and seeks to a specific number of frames from the
   * current time. A negative value will seek backward, and a positive value
   * forward.
   */
  moveByFrameCount(numberOfFrames: number) {
    // Aborts if video is not ready or not paused.
    if (!this.shakaPlayer.paused || this.mainPlayheadTime == null) return;
    const time = this.mainPlayheadTime + FRAME_DURATION_SEC * numberOfFrames;
    this.movePlayheadAndSeek(time);
  }

  movePlayheadAndSeekToRatio(ratio: number) {
    const roundedTime = this.getTimelineStart() +
        Math.round(ratio * this.getTimelineDuration() * 1000) / 1000;
    this.movePlayheadAndSeek(roundedTime);
  }

  /**
   * Moves the playhead and seeks to a specific number of seconds from the
   * current time. A negative value will seek backward, and a positive value
   * forward.
   */
  async movePlayheadSeconds(seconds: number) {
    const seekTime = this.shakaPlayer.currentTime + seconds;

    if (this.featureService.featureOn('enable-fix-for-live-rewind-forward')) {
      if (seconds > 0) {
        if (this.isLiveEdgeActive) {
          return;
        }
        if (this.isNearLiveEdgeTime(seekTime)) {
          this.onLiveIndicatorClicked();
          return;
        }
      }

      if (seconds < 0) {
        this.isLiveEdgeActive = false;
      }
    }

    await this.movePlayheadAndSeek(seekTime, false);
  }

  private isNearLiveEdgeTime(time: number): boolean {
    return this.isLiveActive() && time > this.getLiveEdgeTime() - MAX_DISTANCE_FROM_EDGE_SEC;
  }

  async movePlayheadAndSeek(time: number, pauseRequired = true) {
    if (pauseRequired) {
      this.pause();
    }

    // Prevents to move before the beginning or after the end of the current
    // asset or clip.
    const validTime = Math.max(
        this.getTimelineStart(), Math.min(time, this.getTimelineEnd()));

    this.mainPlayheadTime = validTime;
    await this.shakaPlayer.seek(validTime);
    this.seekOnHoverTime = undefined;
    this.cdr.markForCheck();
  }

  getCurrentPlaybackSpeed(absoluteValue: boolean): number {
    const rate = this.playback.keyboardRange[this.playback.keyboardIndex];
    return absoluteValue ? Math.abs(rate) : rate;
  }

  getCurrentPlaybackSpeedIcon(): PlaybackIcon {
    return this.playback.keyboardRange[this.playback.keyboardIndex] > 0 ?
        PlaybackIcon.FAST_FORWARD :
        PlaybackIcon.FAST_REWIND;
  }

  /** Emits when `setPlaybackSpeed` is called.  */
  playbackRate$ = new BehaviorSubject(1);

  /** Called when a new base rate is selected from the dropdown. */
  onSelectBaseSpeed(baseRate: number) {
    // Only show overlay if player was not paused.
    const showOverlay = !this.isPaused();
    this.setPlaybackSpeed(baseRate, showOverlay, true);
  }

  /** Called when a new seek interval is selected from the dropdown. */
  onSelectSeekInterval(interval: number) {
    this.playback.selectedSeekInterval = interval;
  }

  /**
   * Sets the playback speed of the video whenever a change is made (e.g.
   * dropdown selection, 'jkl' key presses, pauses/play)
   */
  async setPlaybackSpeed(
      newRate: number, showOverlay: boolean, resetKeyboardScale: boolean) {
    this.playbackRate$.next(newRate);

    clearTimeout(this.playback.flashTimer);

    // `resetKeyboardScale` should only happen when the user selects a new
    // playback speed from the dropdown
    if (resetKeyboardScale) {
      this.playback.selectedSpeed = newRate;

      let keyboardRange: number[] = [...KEYBOARD_PLAY_RATES];
      if (newRate === 2) {
        // We don't want to include [-2, 2] twice if the selected speed in the
        // dropdown is 2.
        keyboardRange = keyboardRange.filter(r => Math.abs(r) !== 1);
      } else if (newRate !== 1) {
        // When a base speed different than 2 is selected from the dropdown,
        // it replaces the keyboard speed +1 and -1.
        keyboardRange[KEYBOARD_RATE_PLUS_1_INDEX] = newRate;
        keyboardRange[KEYBOARD_RATE_MINUS_1_INDEX] = -newRate;
      }
      this.playback.keyboardRange = keyboardRange;
    }

    this.playback.keyboardIndex = this.playback.keyboardRange.indexOf(newRate);

    if (showOverlay) {
      this.flashOverlay();
    }

    // If we were speed-seeking and changed the play rate
    if (this.isSpeedSeeking) {
      // New rate is still irregular, the shaka player is not touched.
      if (newRate < 0 || newRate > REGULAR_SEEKING_HIGHEST_RATE) return;

      // New rate is regular, we cancel speed seeking and go back to playing the
      // video normally.
      this.stopSpeedSeek$.next();
      // First resume shaka player
      if (this.mainPlayheadTime != null) {
        await this.shakaPlayer.seek(this.mainPlayheadTime);
        await this.shakaPlayer.play();
      }
      // Then hide thumbnail player
      this.isSpeedSeeking = false;
      this.seekOnHoverTime = undefined;
    }

    // Negative rates will never use shaka player and instead use speed-seeking.
    // Positive rates may use shaka player while the video is buffered, then
    // switch to speed-seeking when necessary.
    if (newRate < 0) return;

    // trickPlay will update the playbackRate of the video but will
    // automatically start playing. If the video was paused, we want to change
    // the playbackRate and keep it paused. setPlaybackRate alone will not work
    // if a change is made while the video is playing
    if (this.shakaPlayer.paused) {
      this.shakaPlayer.setPlaybackRate(newRate);
    } else {
      this.shakaPlayer.trickPlay(newRate);
    }
  }

  /**
   * The thumbnail player uses sprites instead of videos and is used for
   * speed-seeking (VoDs + live), and timeline seek-on-hover (live only).
   */
  isThumbnailPlayerActive() {
    return this.isSpeedSeeking || this.asset?.isLive;
  }

  /**
   * Whether the current playback (which may be from the shaka player or the
   * thumbnail player) is paused.
   */
  isPaused() {
    return this.shakaPlayer?.paused && !this.isSpeedSeeking;
  }

  getPlaybackRate() {
    return this.shakaPlayer.getPlaybackRate();
  }

  getLiveEdgeTime() {
    return this.shakaPlayer.getLiveEdgeTime();
  }

  /**
   * Returns the absolute timestamp(wall-clock beginning) if it's on toggle.
   */
  getStartTimestamp(): number|undefined {
    if (!this.asset) return undefined;

    switch (this.selectedTimeFormat) {
      case TimeFormatEnum.DURATION:
        return undefined;
      case TimeFormatEnum.FROM_ORIGINAL:
        return this.asset.startTime * 1000 - this.timezone.getTimezoneOffset();
      case TimeFormatEnum.TIMECODE:
        return this.assetService.getWallclockStartTimestamp(
            this.asset, this.liveManifestInfo);
      default:
        checkExhaustive(this.selectedTimeFormat);
    }
  }

  getVideoDuration(): number {
    return this.shakaPlayer.duration;
  }

  /** Emits once when the video dispatches a `canplay` event. */
  onNextCanPlay() {
    return this.canPlayVideo$.pipe(distinctUntilChanged(),filter((canPlay)=> !!canPlay));
  }

  getCurrentTrackIndex() {
    return this.shakaPlayer.getCurrentTrackIndex();
  }

  /** Clears state and unloads video (if any). */
  clear() {
    this.shakaPlayer?.unload();
    this.bufferSegments = [];
    this.isAssetLoaded = false;
    this.mainPlayheadTime = undefined;
    this.clipMarking = undefined;
    this.cutDownSegments = undefined;
  }

  /** Checks that there are no existing cutdowns with the same offsets. */
  canConfirmCutdown(marking?: ClipMarking) {
    if (!marking || !this.asset || this.asset.original ||
        !this.cutDownSegments) {
      return false;
    }

    return this.cutDownSegments.every(
        ({startTime, endTime}) =>
            marking.markIn !== startTime || marking.markOut !== endTime);
  }

  /** Handles on-destroy Subject, used for unsubscribing. */
  private readonly destroyed$ = new ReplaySubject<void>(1);

  private readonly stopSpeedSeek$ = new Subject<void>();

  private trackCropperLineHeightInternal = 0;

  /** Whether currently moving cursor over the timeline while paused. */
  private isSeekingTimeline = false;

  /**
   * URL of the DASH manifest that is currently played.
   */
  private currentManifest?: string;

  /**
   * setTimeout reference that will buffer a portion of the video where the
   * user is currently seeking without moving.
   */
  private bufferFromSeekDebouncer = -1;

  /**
   * Whether the player is using thumbnails to play the video at a high rate or
   * negative rate, which would otherwise be limited by buffering.
   */
  private isSpeedSeeking = false;

  /**
   * Emits when we navigate to a new asset, provides additional data along with
   * the asset. Includes an async delay to ensure that it runs after the *ngIf
   * in the template has rendered and the players are in the DOM.
   */
  private readonly assetChanged$ = this.asset$.pipe(
      distinctUntilChanged((a, b) => a?.name === b?.name),
      delay(0),
      switchMap(async (asset) => {
        const manifest = asset ? (await this.getManifestUrl(asset)) : undefined;
        // Asset rendition urls are signed by the backend on every request, so
        // any time we fetch an asset it will have different signatures in urls.
        const currentUrl =
            this.getUrlWithoutSearchParams(this.currentManifest ?? '');
        const newUrl = this.getUrlWithoutSearchParams(manifest ?? '');
        const isSameManifest = currentUrl === newUrl;
        const hasPriorContent = (currentUrl !== '');

        return {asset, manifest, isSameManifest, hasPriorContent};
      }),
      tap(() => {
        this.canPlayVideo$.next(false);
      })
  );

  // shareReplay(1) does not work as it breaks the tests
  private readonly manifestInfoSubject$ = new ReplaySubject<Asset | undefined>(1);
  private readonly manifestChanged$ =
      this.assetChanged$.pipe(filter(({isSameManifest}) => !isSameManifest), tap(({ asset }) => this.manifestInfoSubject$.next(asset)));

  private liveManifestInfo: LiveAssetManifestInfo|null = null;

  private readonly liveManifestInfo$: Observable<LiveAssetManifestInfo|null> =
      this.manifestInfoSubject$.pipe(
          switchMap((asset) => {
            if (!asset?.isLive) {
              return of(null);
            }

            return this.manifestService.getLiveAssetTimeline(asset).pipe(
                mapOnError(() => null));
          }),
      );

  private readonly performanceTrace?: Trace;

  private edgeTimeBeforePause = 0;

  private negativePlaybackReported = false;

  /** Updates playhead visual position by reading the internal video time. */
  private refreshMainPlayhead() {
    this.mainPlayheadTime = this.shakaPlayer.currentTime;
  }

  /** Operations done as soon as we navigate to a new asset. */
  private observeAssetChanged() {
    this.assetChanged$.pipe(takeUntil(this.destroyed$))
        .subscribe(({asset, manifest, isSameManifest, hasPriorContent}) => {
          this.cdr.markForCheck();

          this.assetInternal = asset;
          this.mainPlayheadTime = undefined;
          this.negativePlaybackReported = false;

          // Reset previous asset state.
          this.timeline?.clearMarking();
          this.edgeTimeBeforePause = 0;

          // Make sure that video is paused to avoid receiving undesired
          // onTimeUpdate events from the previous video while loading the next.
          this.pause();

          // Clear buffer (unload) and show placeholder thumbnail if a new
          // video (hence a different manifest) needs to be loaded.
          if (hasPriorContent && (!manifest || !isSameManifest) ) {
            this.clear();
          }

          // No asset was provided, nothing else to do.
          if (!asset) return;

          // Safety check to ensure that the new asset can be played, otherwise
          // redirect the user.
          if (!manifest) {
            this.playerError.emit('Video is not available');
            return;
          }

          // Set the initial volume from what was saved in localStorage. We pass
          // `true` for the second argument so that this initial value set is
          // not logged to firebase or saved in user preferences.
          this.setVolume(this.getVolumeFromStorage(), /* sliding */ true);


          if (
              this.videoProtocolService.isMobileWebKit() &&
              !(this.isLiveWithoutDuration(asset) && asset.state === AssetState.AIRING)
          ) {
              // The WebKit browser does not load the video's initial video frame.
              // As a workaround, the play and pause actions are called.
              this.loadAsset(isSameManifest, manifest, asset)
                  .then(() => this.play())
                  .then(() => this.pause());
          } else {
              this.loadAsset(isSameManifest, manifest, asset);
          }

      // Set the initial track from what was saved in localStorage or the default 2.
      if (this.sendTrackIndex) {
        const trackIdx = this.retrieveTrackFromStore();
        if (trackIdx !== undefined) {
          this.shakaPlayer.defaultTrackIndex = trackIdx === 0 ? 0 : trackIdx;
        } else this.shakaPlayer.defaultTrackIndex = 2;
      }
    });
  }

  /**
   * Loads a new asset video (VoD or live stream) and moves or hides the main
   * playhead accordingly.
   */
  private async loadAsset(
      isSameManifest: boolean, manifest: string, asset: Asset) {
    // Hide playhead until we know where it should be shown.
    this.mainPlayheadTime = undefined;
    this.cdr.detectChanges();

    // For full live assets position the playhead based on manifest type.
    if (this.isLiveWithoutDuration(asset)) {
      // Without initial time set, shaka player will position the playhead to 0
      // if the manifest is static or to live edge for dynamic manifests.
      await this.loadManifest(manifest, asset);
      return;
    }

    // Visually move the playhead:
    // - to clip start for clips of any type
    // - to search result start if available
    // - to 0 for full VoD assets.
    const initialTime = this.querySegment?.startTime || asset.startTime;

    // If the new asset uses the same manifest as the previous one amd the player is ready for streaming,
    // simply seek to the new start.
    if (isSameManifest && this.shakaPlayer.readyForStreaming) {
      await this.movePlayheadAndSeek(initialTime);
      return;
    }

    await this.loadManifest(manifest, asset, initialTime);
  }

  private async loadManifest(
      manifest: string, asset: Asset, initialTime?: number) {
    const trace = this.performanceService.startTrace(
        TraceName.DETAILS_LOAD_MAIN_MANIFEST);

    // The manifest is different from the current one, load it.
    await this.shakaPlayer.loadManifest(manifest, initialTime);

    trace?.stop({
      attributes: {
        assetDuration: asset.duration,
        videoDuration: this.shakaPlayer.duration,
      }
    });

    // In case of an active live stream, start playing automatically (unless we
    // already changed asset while this manifest was being loaded), which will
    // be at the current live time as defined in the live manifest. Clips are
    // not considered live active.
    if (this.asset === asset && this.isLiveActive()) {
      this.isLiveEdgeActive = true;
      await this.shakaPlayer.playMutedIfNecessary();
    }
  }

  /**
   * Starts downloading the given URL once the main DASH video has received
   * its first segment and is ready to play (b/172676655).
   */
  private loadWhenReady(url: string): Observable<string> {
    return this.onNextCanPlay().pipe(
        takeUntil(this.destroyed$),
        // Switchmap on the inner observable ensures that loading of an
        // older seek-on-hover proxy is aborted when starting a new one
        // (b/172676917).
        switchMap(() => this.videoSeek.loadVideo(url)),
    );
  }

  /**
   * Reacts to changes of manifest and loads seek proxies once first frame is
   * displayed.
   */
  private initiateProxyLoading() {
    const vodManifestChanged$ = this.manifestInfoSubject$.pipe(filter((asset) => {
      return !!asset && !asset.isLive;
    }));

    // Clear seek proxies when changing manifest.
    vodManifestChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.videoSeek.src = '';
    });

    // Wait until the new asset can be played (first segment loaded),
    // then load the light seek proxy.
    vodManifestChanged$
        .pipe(
            distinctUntilChanged((a, b) => a?.name === b?.name),
            // Get light seek proxy URL.
            switchMap((asset) => this.getRenditionUrl(
                    castExists(asset), RenditionVersionEnum.SMALL_DYNAMIC_FPS)),
            filter((url): url is string => !!url),
            // Start download once the DASH video is ready.
            switchMap(url => this.loadWhenReady(url)),
            // Abort download if user leaves the details page.
            takeUntil(this.destroyed$),
            // Do not use light seek proxy if HD one was already set.
            filter(() => !this.videoSeek.src),
            )
        .subscribe(inMemoryUrl => {
          this.videoSeek.src = inMemoryUrl;
        });

    // In parallel to the light proxy, load the higher quality seek
    // on hover proxy.
    vodManifestChanged$
        .pipe(
            distinctUntilChanged((a, b) => a?.name === b?.name),
            // Get HD seek proxy URL.
            switchMap((asset) => this.getRenditionUrl(
              castExists(asset), RenditionVersionEnum.PREVIEW_SEEK)),
            filter((url): url is string => !!url),
            // Start download once the DASH video is ready.
            switchMap(url => this.loadWhenReady(url)),
            // Abort download if user leaves the details page.
            takeUntil(this.destroyed$),
            )
        .subscribe(inMemoryUrl => {
          this.videoSeek.src = inMemoryUrl;
        });

    // Remember current manifest to detect whether next asset is using the same.
    this.manifestChanged$.pipe(takeUntil(this.destroyed$))
        .subscribe(({manifest}) => {
          this.currentManifest = manifest;
        });
  }

  /**
   * Operations done every time a new asset is loaded and ready to play.
   */
  private observeAssetLoaded() {
    this.assetChanged$
        .pipe(switchMap(() => this.onNextCanPlay()), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.cdr.markForCheck();
          this.isAssetLoaded = true;
          this.refreshMainPlayhead();
        });
  }

  private getRenditionUrl(asset: Asset, version: RenditionVersion) {
    return this.renditionService.getRenditionUrl(asset, version, false);
  }

  private getManifestUrl(asset: Asset) {
    return this.renditionService.getManifestUrl(asset);
  }

  /**
   * Called when the user mouse over the timeline (seeks) on a portion that is
   * not currently buffered.
   */
  private unbufferedSeek(time: number) {
    this.seekOnHoverTime = time;

    if (!this.isThumbnailPlayerActive()) {
      // Display a frame from the seek light rendition.
      this.videoSeek.fastSeek(this.seekOnHoverTime);
    }

    if (!this.seekCursorDebounceMs) return;

    // If the user stops moving for a short time, we buffer the current seek
    // playhead position and switch to the high-res proxy.
    this.bufferFromSeekDebouncer = window.setTimeout(async () => {
      if (!this.shakaPlayer.paused) return;
      // This will starts buffering this time
      await this.shakaPlayer.seek(time);
      // Once seeking is done, we can display the main buffered video.
      this.seekOnHoverTime = undefined;
      this.cdr.markForCheck();
    }, this.seekCursorDebounceMs);
  }

  /**
   * Changes the keyboard rate depending on the `direction` parameter:
   * `+1`: selects the next rate, for instance from 2x to 4x
   * `-1`: selects the previous rate, for instance from -4x to -8x
   * `0`: show the current rate as an overlay
   */
  private async nextOrPreviousKeyboardRate(direction: -1|0|1) {
    // When unpausing at base speed, don't change the keyboard selected speed.
    if (this.isPaused() && direction === 1) {
      direction = 0;
    }

    // Prevent going outside of the available speed range.
    if (this.playback.keyboardRange[this.playback.keyboardIndex + direction] ==
        null) {
      direction = 0;
    }

    const newRate =
        this.playback.keyboardRange[this.playback.keyboardIndex + direction];

    // If playing forward from a paused state, make sure the player is playing.
    if (this.isPaused() && newRate > 0) {
      await this.shakaPlayer.play();
    }

    await this.setPlaybackSpeed(
        newRate,
        /* showOverlay */ true,
        /* resetKeyboardScale= */ false,
    );
  }

  private flashOverlay() {
    this.playback.isFlashVisible = true;
    this.playback.flashTimer = window.setTimeout(() => {
      this.playback.isFlashVisible = false;
    }, PLAY_RATE_OVERLAY_DURATION_MS);
  }

  /**
   * Returns url part without search params.
   *
   * @example
   * "abc.com?a=1" => "abc.com"
   * @example
   * "xyz.com" => "xyz.com"
   */
  private getUrlWithoutSearchParams(url: string) {
    return url.slice(0, url.indexOf('?'));
  }

  private addSpeedSeekingHook() {
    // Speed-seeking may be updated when changing the playback rate and reaching
    // a non-buffered area. Start process whenever the playback rate changes.
    this.playbackRate$
        .pipe(
            switchMap(rate => {
              // Speed seeking may only be activated for a negative rate, or
              // a rate that is fast enough, otherwise abort.
              if (rate > 0 && rate <= REGULAR_SEEKING_HIGHEST_RATE) {
                return EMPTY;
              }

              // Given the rate is compatible with speed-seeking, in case of
              // forward-playback (positive rate), periodically check whether
              // there is 1s of buffer ahead.
              return timer(0, 1000 / SPEED_SEEKING_FPS)
                  .pipe(
                      // Ignore interval ticks if regular playback works.
                      skipWhile(() => {
                        if (rate < 0 || this.mainPlayheadTime == null) {
                          return false;
                        }
                        const nextSecond = this.mainPlayheadTime + 1;
                        return this.shakaPlayer.isBuffered(nextSecond);
                      }),
                      // Playback is backward or there is not enough buffer
                      // ahead, continue with this observable flow.
                      first(),
                      map(() => rate),
                  );
            }),
            // Start a timer that will drive speed-seeking by updating the
            // thumbnail player time at regular interval, until this process is
            // aborted by `stopSpeedSeek$`.
            switchMap(rate => {
              const intervalMs = (1 / SPEED_SEEKING_FPS) * 1000;
              return interval(intervalMs)
                  .pipe(
                      map(() => rate),
                      takeUntil(this.stopSpeedSeek$),
                  );
            }),
            takeUntil(this.destroyed$),
            )
        .subscribe(rate => {
          this.cdr.markForCheck();

          this.shakaPlayer.pause();
          this.isSpeedSeeking = true;

          const time = this.mainPlayheadTime || 0;
          let nextTime = time + (rate / SPEED_SEEKING_FPS);
          // Round to the nearest millisecond.
          nextTime = Math.round(nextTime * 1000) / 1000;

          const validTime = Math.max(
              this.getTimelineStart(),
              Math.min(nextTime, this.getTimelineEnd()));

          // We speed-seeked to a non-valid time, stop speed seeking.
          if (nextTime !== validTime) {
            this.movePlayheadAndSeek(validTime);
            return;
          }

          this.seekOnHoverTime = nextTime;
          this.mainPlayheadTime = nextTime;
        });
  }

  private emitUpdate() {
    this.playerUpdate.emit({
      time: this.mainPlayheadTime,
      paused: this.isPaused(),
    });
  }

  /**
   * Checks whether the asset is an original live asset
   *
   * Unlike original VoD assets and VoD/live clips original live assets have no
   * duration. It needs to be looked up in the manifest.
   *
   * We check for duration directly instead of checking if this is a clip to be
   * able to construct fake live assets constrained to specific start/end
   * times and duration. This is currently used for cutdown preview.
   */
  private isLiveWithoutDuration(asset: Asset) {
    return asset.isLive && !asset.duration;
  }

  public handleOverLayControlEvent(event: OverlayControlEvent) {
    if (event.type == OverlayControlEventType.PlayPause) {
      this.togglePlayPause();
    } else if (event.type == OverlayControlEventType.Skip) {
      this.movePlayheadSeconds(event.skipSeconds || 0);
    }
  }

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.shakaPlayer) {
      this.shakaPlayer.video.oncanplay = null;
    }
    this.playerFullscreenService.deregisterElement(FullscreenElementPriorityPresets.player.key);
  }
}
