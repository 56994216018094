import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {first, tap} from 'rxjs/operators';

import {isErrorResponse } from '../error_service/error_response';
import {AssetService} from '../services/asset_service';
import {Bin} from '../services/bin.service';
import {LOCATION_ORIGIN} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/** Clipbin creation dialog */
@Component({
  selector: 'mam-rename-clip-dialog',
  templateUrl: './rename_clip_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameClipDialog implements AfterViewInit {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      readonly dialogRef: MatDialogRef<RenameClipDialog, string>,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      @Inject(LOCATION_ORIGIN) private readonly origin: string,
      @Inject(MAT_DIALOG_DATA) public data: {name: string, title: string, bin: Bin}) {
    dialogRef.afterClosed().subscribe((name) => {
      if (!name?.trim() || name === this.data.title) return;
      this.assetService.renameClip(this.data.name, name).subscribe({
        next: () => {
          this.createOrUpdateClipBinSharedLink(this.data.bin);
          this.snackBar.message('Clip has been renamed successfully.');
        },
        error: (error) => {
          this.snackBar.error('Clip could not be renamed.', undefined, error);
        }
      });
    });
  }

  ngAfterViewInit() {
    // Select input on next tick to avoid an
    // `ExpressionChangedAfterItHasBeenCheckedError`, see
    // https://angular.io/errors/NG0100.
    setTimeout(() => {
      this.input.nativeElement.select();
    });
  }

  checkInputBeforeClose(value: string) {
    if (value.trim() === '') return;
    this.dialogRef.close(value);
  }

  private createOrUpdateClipBinSharedLink(clipbin: Bin) {
    const encodeClipBinName = encodeURIComponent(clipbin.name);
    this.sharedLinkClipBinService.retrieveIASClipBinShareLink(encodeClipBinName, this.origin, true)
      .pipe(
        first(),
        tap(binResponse => {
          if (!isErrorResponse(binResponse) && (binResponse.length > 0)){
            this.sharedLinkClipBinService.createOrUpdateClipBinSharedLink(clipbin);
          }
        })
      )
      .subscribe();
  }
}
