import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { StorageLocalService } from 'services/storage/storage_local.service';

import { ClipLocalStorage, IClipBinStorage } from '../../models/storage.model';

export interface IStorage {
	clips: ClipLocalStorage[];
	ownerId: string;
}

export const INITIAL_STATE: IStorage = {
	clips: [],
	ownerId: ''
};

@Injectable({
	providedIn: 'root'
})
export class ClipbinStorageService extends StorageLocalService<IClipBinStorage> {
	override store$: BehaviorSubject<IClipBinStorage> = new BehaviorSubject<IClipBinStorage>(INITIAL_STATE);

	override key = 'Clip bin sharing';

	clipbinOwner: string = this.store$.getValue().ownerId;

	clipsList: ClipLocalStorage[] = this.store$.getValue().clips;

	constructor() {
		super();
		this.store$.subscribe((clipbin) => {
			this.clipbinOwner = clipbin.ownerId;
			this.clipsList = clipbin.clips;
		});
	}

	init() {
		this.set<IStorage>(INITIAL_STATE);
	}

	setClipBinSharing(clipbin: IClipBinStorage) {
		this.set(clipbin);
	}

	updateClip(clip: ClipLocalStorage): Observable<ClipLocalStorage> {
		if (this.clipsList.some((item) => item.name === clip.name)) {
			const idx = this.clipsList.findIndex((item) => item.name === clip.name);
			this.clipsList[idx] = clip;
			return this.update({ clips: this.clipsList, ownerId: this.clipbinOwner });
		}
		const updatedClipList = this.clipsList.concat(clip);
		return this.update({ clips: updatedClipList, ownerId: this.clipbinOwner });
	}

	getClip(clipName: string): ClipLocalStorage | undefined {
		return this.clipsList.find((item) => item.name === clipName);
	}

	removeClip(clipId: string): Observable<IClipBinStorage> {
		const idx = this.clipsList.findIndex((item) => item.name === clipId);
		if (idx === -1) return of();
		this.clipsList.splice(idx, 1);
		return this.update({ clips: this.clipsList, ownerId: this.clipbinOwner });
	}

	updateOwner(owner: string) {
		this.set({ clips: this.clipsList, ownerId: owner });
	}

	/** Set item with initial state */
	override clear(): void {
		this.set(INITIAL_STATE);
		this.store$.next(INITIAL_STATE);
	}

	/** Delete item from local storage */
	override delete(): void {
		localStorage.removeItem(this.key);
	}

  getIndexByName(name: string): number | undefined {
    return this.getClip(name)?.audioTrack?.current;
  }
}
