<h1 class="dialog_get-link__title" mat-dialog-title>Get link for Clip Bin '{{ data.bin.title }}'</h1>

<h2 class="dialog_get-link__subtitle" *ngIf="clipChanges.length > 0">Sharing a clip bin that contains clips with custom audio track settings will
  overwrite the default clips.</h2>

<div mat-dialog-content class="dialog_get-link--spacing">
  <!-- URL and copy button -->
  <section class="url-area">
    <mat-form-field class="dialog__input url-area__input--spacing" appearance="outline" subscriptSizing="dynamic">
      <mat-icon matPrefix>share</mat-icon>
      <input
          matInput
          readonly
          [value]="url"
          placeholder="loading..."
          aria-label="URL of the shared bin link">
    </mat-form-field>
    <button
        [disabled]="!url"
        class="copy-url"
        mat-flat-button
        mam-firebase-ga="Copy Shared Link Url for Bin"
        [mam-firebase-resource]="data.bin.title"
        [cdkCopyToClipboard]="url"
        (click)="snackbarClipboardCopy()">
      Copy
    </button>
  </section>

  <!-- Expiration time menu selection -->
  <button
      mat-button
      class="expiration-selector"
      [disabled]="!url || isUpdatingExpiration"
      [matMenuTriggerFor]="expirationsMenu"
      #menuTrigger="matMenuTrigger"
      mam-firebase-ga="Open expiration time picker"
      [mam-firebase-resource]="data.bin.title">
    <span>{{formatExpiration(selectedDaysOption)}}</span>
    <mat-icon class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
  </button>

  <mat-menu class="custom-menu" #expirationsMenu="matMenu">
    <ng-container *ngFor="let daysOption of expirationDaysOptions">
    <button
        *ngIf="daysOption"
        mat-menu-item
        (click)="selectDaysOption(daysOption)"
        mam-firebase-ga="Select expiration time"
        mam-firebase-resource="{{daysOption}}">
      <mat-icon [class.invisible]="selectedDaysOption !== daysOption">
        done
      </mat-icon>
      <span>{{ formatExpiration(daysOption) }}</span>
    </button>
    </ng-container>
  </mat-menu>
</div>

<!-- Changes list -->
<section class="dialog_get-link__changes"  *ngIf="clipChanges.length > 0">
  <header class="dialog_get-link__changes_header">
    <h3 class="dialog_get-link__changes__title">Clips changed</h3>
    <p class="dialog_get-link__changes__description">List of clips with modified settings from the player.</p>
  </header>
  <ul class="dialog_get-link__changes_list changes_list">
    <li class="changes_list_header">
      <p>Title</p>
      <p>Track changes <small>(From/To)</small></p>
    </li>

    <li class="changes_list__item" *ngFor="let change of clipChanges">
      <p class="changes_list__item__name">{{ change.title }}</p>
      <div class="changes_list__item__changes">
        <span>{{ change.audioTrack.previous + 1 }}</span>
        <mat-icon>arrow_right</mat-icon>
        <span>{{ change.audioTrack.current + 1 }}</span>
      </div>
    </li>
  </ul>
</section>

<div mat-dialog-actions class="dialog-get-link__actions--position">
  <button
    mat-button
    [mat-dialog-close]="selectedDaysOption"
    class="primary dialog__button--position"
    mam-firebase-ga="Close link bin sharing dialog"
    [mam-firebase-resource]="data.bin.title">
    Done
  </button>
</div>
