import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { take, tap } from 'rxjs';

import {assertTruthy} from 'asserts/asserts';
import {SharedLink} from 'models';

import {isErrorResponse} from '../error_service/error_response';
import {Asset} from '../services/asset_service';
import {ExpirationDaysOption, SharedLinksService} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';

/** Input data to this dialog */
export interface GetLinkForVideoDialogInputData {
  /** Asset (original or clip) shared. */
  asset: Asset;
  /** Additional properties to add to the shared link. */
  additionalProperties?: Record<string, string>;
}

/** Dialog with generated shared link URL. */
@Component({
  selector: 'mam-get-link-for-video-dialog',
  templateUrl: './get_link_for_video_dialog.ng.html',
  styleUrls: ['./get_link_for_video_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetLinkForVideoDialog {
  static getDialogOptions(data: GetLinkForVideoDialogInputData):
      MatDialogConfig<GetLinkForVideoDialogInputData> {
    return {
      data,
      maxWidth: 640,
      width: '90%',
    };
  }

  selectedDaysOption = this.sharedLinks.defaultExpirationDays;

  readonly expirationDaysOptions = this.sharedLinks.expirationDaysOptions;

  /** Generated URL to actually access the video shared by this link. */
  url = '';

  originalHash = '';

  isFullClip: boolean | undefined = false;

  isUpdatingExpiration = false;

  constructor(
      readonly dialogRef: MatDialogRef<GetLinkForVideoDialog>,
      private readonly sharedLinks: SharedLinksService,
      private readonly snackbar: SnackBarService,
      private readonly cdr: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) readonly data: GetLinkForVideoDialogInputData,
  ) {
    this.sharedLinks.createLink(data.asset, data.additionalProperties)
      .subscribe(link => {
        this.cdr.markForCheck();

        if (isErrorResponse(link)) {
          this.snackbar.error({
            message: 'Failed to share asset',
            details: link.message,
            doNotLog: true,
          });
          dialogRef.close();
          return;
        }

        this.link = link;
        this.url = this.sharedLinks.getLinkUrl(link);
      });

    this.isFullClip = data.asset.original && data.asset.original.duration?.toFixed(0) === data.asset.duration?.toFixed(0);

    if(this.isFullClip) {
      this.sharedLinks.createFullClipLink(data.asset, data.additionalProperties).pipe(
        take(1),
        tap((link) => {
          this.cdr.markForCheck();
          this.originalHash = this.sharedLinks.getOriginalHash(link as SharedLink);
        })).subscribe();
    }
  }

  selectDaysOption(selection: ExpirationDaysOption) {
    // We can only select a different expiration once we have a `url` which
    // implies that we have a link. Check for future-proofness.
    const link = this.link;
    assertTruthy(
        link,
        'GetLinkForVideoDialog.selectDaysOption: Link has not been created yet');

    const previousSelection = this.selectedDaysOption;
    if (selection === previousSelection) return;

    this.isUpdatingExpiration = true;
    this.sharedLinks.updateLinkTtl(link.name, selection).subscribe(link => {
      this.cdr.markForCheck();
      this.isUpdatingExpiration = false;

      if (link) {
        this.selectedDaysOption = selection;
      } else {
        this.snackbar.error('Failed to change the link expiration time');
      }
    });
  }

  formatExpiration(expirationDays: ExpirationDaysOption) {
    switch (expirationDays) {
      case 0:
        return 'Link NEVER expires';
      case 1:
        return 'Link expires in 1 day';
      default:
        return `Link expires in ${expirationDays} days`;
    }
  }

  snackbarClipboardCopy() {
    this.snackbar.message('URL copied to clipboard.');
  }

  /** Link resource that is created upon dialog opening. */
  private link?: SharedLink;
}
