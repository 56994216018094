<h1 mat-dialog-title>Manage sharing links</h1>

<h2>View all your shared links and revoke link access.</h2>

<div mat-dialog-content>
    <button class="search_mode" mat-button mat-flat-button [matMenuTriggerFor]="menuClipBinOwner">
        <span class="search_mode__text">{{ getSearchModeText(searchMode) }}</span>
        <mat-icon matSuffix class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menuClipBinOwner="matMenu" class="clip-bin-owner-menu">
        <button
            *ngFor="let type of sharedLinkTypes"
            mat-menu-item
            (click)="changeSearchMode(type)"
            mam-firebase-ga="Select clipbins owner type"
            [mam-firebase-resource]="getSearchModeText(type)"
        >
            <mat-icon [style.opacity]="searchMode === type ? 1 : 0"> done </mat-icon>
            <span>{{getSearchModeText(type)}}</span>
        </button>
    </mat-menu>

    <div class="search">
        <mat-form-field
            class="dialog__input dialog__input-search--spacing dialog__input--size"
            appearance="outline"
            subscriptSizing="dynamic"
        >
            <mat-icon matPrefix>search</mat-icon>
            <input
                type="text"
                matInput
                placeholder="Search"
                autocomplete="off"
                [formControl]="titleQuery"
                aria-label="Search links"
            />
        </mat-form-field>
    </div>

    <div class="rows" [class.loaded]="!loading" infinite-scroll (nearBottom)="loadMore(20)">
      <mat-progress-bar
        class="progress-bar-staging-and-jobs-mobile"
        *ngIf="loading"
        @fadeProgressBar
        mode="indeterminate">
      </mat-progress-bar>


        <div
            class="row"
            *ngFor="let link of filteredLinks"
            [class.clipbin_row]="link.type === 'CLIPBIN'"
            [class.to-be-revoked]="revokeSet.has(link)"
        >
            <mat-icon class="material-icons-outlined link-type">
                {{link.type === 'CLIPBIN' ? "subscriptions" : "smart_display"}}
            </mat-icon>
            <div class="title-actions-wrapper">
                <span class="title" [title]="link.title">{{link.title}}</span>
                <div class="row__ttl-actions">
                    <ng-container *ngIf="link.isEditable; else default">
                        <div class="input__editable">
                            <mat-select [hideSingleSelectionIndicator]="true" [(ngModel)]="link.editableTtl">
                                <mat-option [value]="30">30</mat-option>
                                <mat-option [value]="7">7</mat-option>
                                <mat-option [value]="1">1</mat-option>
                            </mat-select>
                        </div>
                        <button
                            mat-icon-button
                            class="cancel"
                            (click)="link.isEditable = false; link.editableTtl = link.originalTtl"
                        >
                            <mat-icon>close</mat-icon>
                        </button>

                        <button
                            class="sync"
                            *ngIf="link.type === 'CLIPBIN'"
                            mat-icon-button
                            [disabled]="(+(link.editableTtl ?? 0) === link.originalTtl)"
                            (click)="updateTtlClipbins(link)"
                        >
                            <mat-icon>done</mat-icon>
                        </button>
                    </ng-container>

                    <ng-template #default>
                        <span
                            class="manager-link__ttl-align"
                            [class.button-gap]="!link.isEditable"
                            [title]="link.originalTtl"
                            [class.visibility-hidden]="revokeSet.has(link)"
                        >
                            {{ link.originalTtl }} {{ link.originalTtl ? link.originalTtl > 1 ? 'days': 'day' : '' }}
                        </span>

                        <button
                            class="sync"
                            *ngIf="link.type === 'CLIPBIN'"
                            mat-icon-button
                            (click)="link.isEditable = true"
                            [class.visibility-hidden]="revokeSet.has(link)"
                        >
                            <mat-icon>sync</mat-icon>
                        </button>
                    </ng-template>
                </div>
            </div>
            <div class="bar"></div>

            <button
                class="link"
                mat-icon-button
                mam-firebase-ga="Copy shared link to clipboard"
                [mam-firebase-resource]="link.name"
                [cdkCopyToClipboard]="getLinkUrl(link)"
                aria-label="Copy video URL to clipboard"
                matTooltip="Copy video URL to clipboard"
                [matTooltipShowDelay]="800"
                [class.visibility-hidden]="revokeSet.has(link)"
            >
                <mat-icon>link</mat-icon>
            </button>
            <button
                class="delete"
                mat-icon-button
                mam-firebase-ga="Revoke shared link"
                [mam-firebase-resource]="link.name"
                aria-label="Revoke link"
                matTooltip="Revoke link"
                [matTooltipShowDelay]="800"
                (click)="revokeSet.has(link) ? revokeSet.delete(link) : revokeSet.add(link);"
            >
                <mat-icon>{{revokeSet.has(link) ? 'close' : 'delete_forever'}}</mat-icon>
            </button>
        </div>
    </div>
    <div class="message">{{getMessage()}}</div>
    <p [class.visibility-hidden]="revokeSet.size === 0" class="marked-revoke">Marked to revoke: {{ revokeSet.size }}</p>
    <p [class.visibility-hidden]="revokeSet.size === 0" class="marked-revoke-info">
        <mat-icon>info</mat-icon> The Selected shared links will be removed.
    </p>
</div>

<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close mam-firebase-ga="Cancel changes to shared links">Cancel</button>
    <button mat-button [mat-dialog-close]="true" class="primary" mam-firebase-ga="Confirm changes to shared links">
        Save
    </button>
</div>
