import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {first, tap} from 'rxjs/operators';

import {isErrorResponse } from '../error_service/error_response';
import {AssetService} from '../services/asset_service';
import {Bin} from '../services/bin.service';
import {LOCATION_ORIGIN} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/** Clip deletion dialog */
@Component({
  selector: 'mam-delete-clip-dialog',
  templateUrl: './delete_clip_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteClipDialog implements OnInit {
  constructor(
      readonly dialogRef: MatDialogRef<DeleteClipDialog, boolean>,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      @Inject(LOCATION_ORIGIN) private readonly origin: string,
      @Inject(MAT_DIALOG_DATA) public data: {name: string, bin: Bin}) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(confirmed => {
      if (!confirmed) return;
      this.assetService.deleteClip(this.data.name).subscribe({
        next: () => {
          this.createOrUpdateClipBinSharedLink(this.data.bin);
          this.snackBar.message('Clip deleted successfully.');
        },
        error: (error) => {
          this.snackBar.error('Clip could not be deleted.', undefined, error);
        }
      });
    });
  }

  private createOrUpdateClipBinSharedLink(clipbin: Bin) {
    const encodeClipBinName = encodeURIComponent(clipbin.name);
    this.sharedLinkClipBinService.retrieveIASClipBinShareLink(encodeClipBinName, this.origin, true)
      .pipe(
        first(),
        tap(binResponse => {
          if (!isErrorResponse(binResponse) && (binResponse.length > 0)){
            this.sharedLinkClipBinService.createOrUpdateClipBinSharedLink(clipbin);
          }
        })
      )
      .subscribe();
  }
}
