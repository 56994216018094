import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {EMPTY, firstValueFrom, Observable, ReplaySubject} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {isErrorResponse} from '../error_service/error_response';
import {Asset, AssetService, ClipMarking} from '../services/asset_service';
import {BinService} from '../services/bin.service';
import {LOCATION_ORIGIN} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/**
 * Dialog that allows naming a clip and adding it to one or multiple clipbins.
 */
@Component({
  selector: 'mam-add-clip-dialog',
  templateUrl: './add_clip_dialog.ng.html',
  styleUrls: ['./add_clip_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddClipDialog implements AfterViewInit {
  static getDialogOptions(data: AddClipDialogInputData,maxWidth?: string):
      MatDialogConfig<AddClipDialogInputData> {
    return {data, maxWidth};
  }

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  readonly defaultClipTitle?: string;

  readonly defaultBinNameSelected?: string;

  /** Set directly from the template. */
  selectedBins: string[] = [];

  constructor(
      readonly dialogRef: MatDialogRef<AddClipDialog, AddClipDialogOutputData>,
      @Inject(MAT_DIALOG_DATA) public data: AddClipDialogInputData,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly binService: BinService,
      private readonly stateService: StateService,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      @Inject(LOCATION_ORIGIN) private readonly origin: string,
  ) {
    this.defaultBinNameSelected = this.stateService.persistentBinName$.value;

    this.defaultClipTitle = this.data.title;
    if (this.defaultClipTitle) return;

    // If a clip marking is provided, default to the current asset's title.
    // Otherwise, since we are adding the full asset, default to the original
    // title.
    if (this.data.clipMarking) {
      this.defaultClipTitle = this.data.asset.title;
    } else {
      const original = this.data.asset.original || this.data.asset;
      this.defaultClipTitle = original.title;
    }
  }

  ngAfterViewInit() {
    // Select input on next tick to avoid an
    // `ExpressionChangedAfterItHasBeenCheckedError`, see
    // https://angular.io/errors/NG0100.
    setTimeout(() => {
      this.input.nativeElement.select();
    });
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  onClick(event: UIEvent) {
      event.stopPropagation();
  }

  onKeyboardEventHandler(event: KeyboardEvent, title: string) : void {
    if (event.key === 'Enter') {
      this.createClips(title);
    }
  }

  /**
   * If valid, closes the dialog with an observable that will eventually emit
   * whether the clip creation succeeded.
   */
  async createClips(title: string) {
    if (!title.trim() || !this.selectedBins.length) return;

    const selectedBins = this.selectedBins;

    const success$ = new ReplaySubject<boolean>(1);

    this.dialogRef.close(success$);

    function done(success: boolean) {
      success$.next(success);
      success$.complete();
    }

    if (!selectedBins?.length) {
      done(false);
      return;
    }

    // When going to the detail page from a clip view and creating a clip, we
    // should use the original asset rather than the clip
    const original = this.data.asset.original || this.data.asset;

    let clipMarking = this.data.clipMarking;

    // Case of adding a full title to a clipbin.
    if (!clipMarking) {
      const durationResponse =
          await firstValueFrom(this.assetService.getAssetDuration(original));

      if (isErrorResponse(durationResponse)) {
        this.snackBar.error({
          message: 'A clip must have a duration',
          details: durationResponse.message
        });
        done(false);
        return;
      }

      clipMarking = {
        markIn: 0,
        markOut: durationResponse,
      };
    }

    const {markIn, markOut} = clipMarking;

    this.assetService
        .createClipInMultipleBins(
            original, selectedBins, markIn, markOut, title)
        .subscribe(numberOfFailures => {
          const success = numberOfFailures === 0;
          if (success) {
            if (selectedBins.length === 1) {
              this.createOrUpdateClipBinSharedLink(selectedBins[0]);
              this.snackBar.message(`Clip added successfully.`);
            } else {
              selectedBins.forEach((bin) => {
                this.createOrUpdateClipBinSharedLink(bin);
              });
              this.snackBar.message(`Clip added to ${
                  selectedBins.length} clip bins successfully.`);
            }
          } else {
            if (selectedBins.length === 1) {
              this.snackBar.error(`Failed to add clip, please try again.`);
            } else {
              this.snackBar.error(`Failed to add clip to ${
                  numberOfFailures} clip bins, please try again.`);
            }
          }

          done(success);
        });
  }

  private createOrUpdateClipBinSharedLink(clipBinName: string) {
    const encodeClipBinName = encodeURIComponent(clipBinName);
    this.sharedLinkClipBinService.retrieveIASClipBinShareLink(encodeClipBinName, this.origin, true)
      .pipe(
        switchMap((response) => {
          return (response.length > 0) ? this.binService.getBin(clipBinName) : EMPTY;
        }),
        tap(binResponse => {
          if (!isErrorResponse(binResponse)) this.sharedLinkClipBinService.createOrUpdateClipBinSharedLink(binResponse);
        })
      )
      .subscribe();
  }
}

/** Input data to this dialog */
export interface AddClipDialogInputData {
  title?: string;
  asset: Asset;
  clipMarking?: ClipMarking;
}

/** Emits whether the API call was made and succeeded. */
export type AddClipDialogOutputData = Observable<boolean>|'';
