import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {EMPTY, forkJoin, Observable, tap} from 'rxjs';
import { catchError, concatMap, expand, filter, finalize, map } from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import {SharedLink} from 'models';
import { ClipbinStorageService } from 'services/storage/clipbin_sharing_storage.service';

import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {ClipLocalStorage} from '../models/storage.model';
import {LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK} from '../right_panel/bulk_clips_actions';
import {AssetService, Clip} from '../services/asset_service';
import {BinWithClips} from '../services/bin.service';
import {ExpirationDaysOption, LOCATION_ORIGIN, SharedLinksService} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';
import {TimezoneService} from '../services/timezone_service';
import {IASClipBinShareLinkData} from '../shared_clipbin/models/shared_link_clipbin.model';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/** Input data to this dialog */
export interface GetLinkForBinDialogInputData {
  /** ClipBin shared. */
  bin: BinWithClips;
  /** Additional properties to add to the shared link. */
  additionalProperties?: Record<string, string>;
}

export interface DialogSharedLink {
  currentClip: Clip;
  currentSharedLink: SharedLink|ErrorResponse;
}

/** Dialog with generated shared link URL. */
@Component({
  selector: 'mam-get-link-for-bin-dialog',
  templateUrl: './get_link_for_bin_dialog.ng.html',
  styleUrls: ['./get_link_for_bin_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetLinkForBinDialog {
  static getDialogOptions(data: GetLinkForBinDialogInputData):
      MatDialogConfig<GetLinkForBinDialogInputData> {
    return {
      data,
      maxWidth: 640,
      width: '90%',
    };
  }

  selectedDaysOption = this.sharedLinkClipBinService.clipbinDefaultExpirationDays;

  readonly expirationDaysOptions = this.sharedLinks.expirationDaysOptions;

  /** Generated URL to actually access the video shared by this link. */
  url = '';

  isUpdatingExpiration = false;

  clipChanges: ClipLocalStorage[] = this.clipBinSharingStorage.clipsList;

  constructor(
      readonly dialogRef: MatDialogRef<GetLinkForBinDialog>,
      private readonly assetService: AssetService,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      private readonly sharedLinks: SharedLinksService,
      private readonly snackbar: SnackBarService,
      private readonly cdr: ChangeDetectorRef,
      private readonly timezone: TimezoneService,
      private readonly clipBinSharingStorage: ClipbinStorageService,
      @Inject(MAT_DIALOG_DATA) readonly data: GetLinkForBinDialogInputData,
      @Inject(LOCATION_ORIGIN) private readonly origin: string,
  ) {

    const clipList$: Observable<DialogSharedLink|ErrorResponse>[] = [];
    this.getAllClips(data.bin.name)
      .pipe(
        filter(clip => this.isVideoShareable(clip)),
        tap(clip => {

          const trackIndexValue = this.clipBinSharingStorage.getIndexByName(clip.name);
          const trackIndex = trackIndexValue !== undefined ? String(trackIndexValue) : undefined;
          const additionalProperties = trackIndex !== undefined ? {...data.additionalProperties, trackIndex} : { ...data.additionalProperties };

          clipList$.push(this.sharedLinks.createLink(clip, additionalProperties).pipe(
            map(link => {return {currentClip: clip, currentSharedLink: link };})
          ));
        }),
        finalize(() => {
          this.createBulkClipShareLinks(clipList$,data.bin.title,data.bin.name);
        })
      )
      .subscribe();
  }

  createBulkClipShareLinks(clipList$: Observable<DialogSharedLink|ErrorResponse>[], binTitle: string, binName: string){
    const encodedBinName = encodeURIComponent(binName);
    forkJoin(clipList$)
      .pipe(
        map(validResponses => validResponses.map(link => {
          const currentLink : DialogSharedLink|ErrorResponse = link;
          if (isErrorResponse(currentLink)) {
            this.snackbar.error({
              message: 'Failed to share bin',
              details: currentLink.message,
              doNotLog: true,
            });
            this.dialogRef.close();
            return {
              assetName: '',
              title: '',
              link: '',
              duration: 0
            };
          }
          return {
            assetName:  (currentLink.currentSharedLink as SharedLink).name ,
            title: (currentLink.currentSharedLink as SharedLink).title,
            link: this.sharedLinks.getClipBinAssetLinkHash(currentLink.currentSharedLink as SharedLink),
            duration: (currentLink.currentClip as Clip).duration
          };
        })),

        // Error Handling (Improved)
        catchError(error => {
          this.snackbar.error({
            message: 'Failed to share bin',
            details: error.message,
            doNotLog: true,
          });
          this.dialogRef.close();
          return EMPTY;
        }),
        finalize(() => {
          // Any cleanup or final actions can be placed here
        })
      )
      .subscribe(results => {
        const clipBinShareLinkData: IASClipBinShareLinkData = {
          createTime: this.timezone.formatTimestampToISOString(Date.now()),
          clipBinTitle: binTitle,
          clipBinName: encodedBinName,
          clipBinSharedLink: this.sharedLinks.getClipBinLinkUrl(encodedBinName),
          domain: this.origin,
          ttl: this.selectedDaysOption,
          isActive: true,
          clipSharedLinks: results
        };

        this.createOrUpdateClipBinSharedLink(encodedBinName,clipBinShareLinkData).then(
          () => {
            this.cdr.markForCheck();
            this.url = this.sharedLinks.getClipBinLinkUrl(encodedBinName);
            return;
          }
        );
      });
  }

  async createOrUpdateClipBinSharedLink(encodedBinName: string, clipBinShareLinkData: IASClipBinShareLinkData){
    await this.sharedLinkClipBinService.updateActiveStatusIASClipBinShareLink(encodedBinName,this.origin,false);
    await this.sharedLinkClipBinService.createIASClipBinShareLink(clipBinShareLinkData);
  }

  isVideoShareable(clip: Clip) {
    return this.assetService.isVideoShareable(clip);
  }

  selectDaysOption(selection: ExpirationDaysOption) {
    // Currently, expiration can only be set after a URL exists, as it's tied to the link creation process.
    // This might need revisiting for more flexible expiration options later.
    const url = this.url;
    assertTruthy(
        url,
        'GetUrlForBinDialog.selectDaysOption: Url for Bin has not been created yet');

    const previousSelection = this.selectedDaysOption;
    if (selection === previousSelection) return;

    this.isUpdatingExpiration = true;
    this.sharedLinkClipBinService.updateSharedLinkTtl(url, selection).subscribe(link => {
      this.cdr.markForCheck();
      this.isUpdatingExpiration = false;

      if (link) {
        this.selectedDaysOption = selection;
      } else {
        this.snackbar.error('Failed to change the link for clip bin expiration time');
      }
    });
  }

  formatExpiration(expirationDays: ExpirationDaysOption) {
    return (expirationDays === 1) ?
      'Link for clip bin expires in 1 day' :
      `Link for clip bin expires in ${expirationDays} days`;
  }

  snackbarClipboardCopy() {
    this.snackbar.message('URL for clip bin copied to clipboard.');
  }

  getAllClips(clipbinName: string): Observable<Clip> {
    let count = 0;
    return this.assetService
      .searchClips(clipbinName, undefined, '', LIST_ALL_CLIPS_PAGE_SIZE)
      .pipe(
        // Expand all pages of clips.
        expand((response) => {
          if (isErrorResponse(response)) return EMPTY;
          if (!response?.nextPageToken) return EMPTY;
          // Stop loading more clips if we reach the upper limit.
          if (count >= MAX_CLIPS_COUNT_FOR_BULK) return EMPTY;
          // Trim pageSize to not load more than our limit.
          const pageSize = Math.min(
            LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK - count);
          // Make the next page API call.
          return this.assetService.searchClips(
            clipbinName, undefined, response.nextPageToken,
            pageSize);
        }),
        // Extract clips from each page response.
        concatMap(response => {
          if (isErrorResponse(response)) return [];
          // Local side-effect for failsafe limit.
          count += response.assets.length;
          return response.assets;
        }),
      );
  }
}
