import { Inject, Injectable, NgZone } from '@angular/core';
import { doc, Firestore, QueryFieldFilterConstraint, updateDoc, where } from '@firebase/firestore';
import { EMPTY, forkJoin, Observable, of, tap } from 'rxjs';
import { catchError, concatMap, expand, filter, finalize, map } from 'rxjs/operators';

import { AuthService } from '../../auth/auth_service';
import { ErrorResponse, isErrorResponse } from '../../error_service/error_response';
import { FirebaseResolver } from '../../firebase/firebase_resolver';
import { SharedLink } from '../../models';
import { LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK } from '../../right_panel/bulk_clips_actions';
import { AssetService, Clip } from '../../services/asset_service';
import { Bin } from '../../services/bin.service';
import { LOCATION_ORIGIN, SharedLinksService } from '../../services/shared_links_service';
import { TimezoneService } from '../../services/timezone_service';
import { DialogSharedLink } from '../../shared/get_link_for_bin_dialog';
import { IASClipBinShareLinkData } from '../models/shared_link_clipbin.model';

export enum SharedLinkType {
  ALL = 'all',
  CLIPBINS = 'clipbins',
  CLIPS = 'clips'
}

export type ClipBinExpirationDaysOption =
  SharedLinkClipBinService['clipbinExpirationDaysOptions'][number];

@Injectable({ providedIn: 'root' })
export class SharedLinkClipBinService {
    constructor(
        private readonly firebaseResolver: FirebaseResolver,
        private readonly assetService: AssetService,
        private readonly sharedLinks: SharedLinksService,
        private readonly authService: AuthService,
        private readonly timezone: TimezoneService,
        private readonly ngZone: NgZone,
        @Inject(LOCATION_ORIGIN) private readonly origin: string,
    ) {}

  /**
   * Possible number of days before a shared link expires.
   * Value '0' is considered as 'never expired'.
   * For now '0' isn't used and skipped to be shown in the options dropdown.
   * But this value '0' is mandatory here because of the changes in the underlying
   * service(s) related to the 'clip sharing - expiration date never' functionality
   */
  readonly clipbinExpirationDaysOptions = [30, 7, 1, 0] as const;

  readonly clipbinDefaultExpirationDays: ClipBinExpirationDaysOption = 30;


    async createIASClipBinShareLink(data: IASClipBinShareLinkData) {
      data.emailID = this.authService.getUserEmail();
      data.username = this.authService.getUserName();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc('ias-clipbin-share-link', data);
      });
    }

    retrieveIASClipBinShareLink(clipbinName: string, domain: string, isActive: boolean) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('clipBinName', '==', clipbinName),
        where('domain', '==', domain),
        where('isActive', '==', isActive),
      ];

      return this.queryIASClipBinShareLink(constraints);
    }

  /**
   * Retrieve the owner of a shared clip bin.
   *
   * @param clipbinName The name of the clip bin.
   * @returns An observable that emits the owner's email ID.
   */
  retrieveIASClipBinOwner(clipbinName: string) {
    const constraints: QueryFieldFilterConstraint[] = [
      where('clipBinName', '==', clipbinName),
      where('isActive', '==', true),
    ];
    return this.queryIASClipBinShareLink(constraints).pipe(map((data) => (data[0] ? data[0].emailID || '' : '')));
  }

  updateTTLIASClipBinShareLink(clipbinName: string, domain: string, ttl: number) {
    const constraints: QueryFieldFilterConstraint[] = [
      where('clipBinName', '==', clipbinName),
      where('domain', '==', domain),
    ];
    return this.updatePartialValueInDocuments('ias-clipbin-share-link', constraints, { ttl: ttl });
  }

    updateActiveStatusIASClipBinShareLink(clipbinName: string, domain: string, isActive: boolean) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('clipBinName', '==', clipbinName),
        where('domain', '==', domain),
      ];
      return this.updatePartialValueInDocuments('ias-clipbin-share-link',constraints,{isActive:isActive});
    }

    mapClipBinShareLinkToUIShareLink(clipBinShareLink: IASClipBinShareLinkData): SharedLink {
      const link = new SharedLink({
        title: clipBinShareLink.clipBinTitle,
        ttl: clipBinShareLink.ttl.toPrecision(1),
        name: clipBinShareLink.clipBinName,
        additionalProperties: {},
      });

      link.type = 'CLIPBIN' ;
      link.url = clipBinShareLink.clipBinSharedLink;
      link.originalTtl = clipBinShareLink.ttl;
      link.editableTtl = clipBinShareLink.ttl;
      link.documentId = clipBinShareLink.documentId ?? '';
      link.clipSharedLinks = clipBinShareLink.clipSharedLinks?.map(c => { return {assetName: c.assetName}; });

      return link;
    }

    retrieveActiveIASClipBinShareLinksByUser(userEmail: string, domain: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('emailID', '==', userEmail),
        where('domain', '==', domain),
        where('isActive', '==', true)
      ];
      return this.queryIASClipBinShareLink(constraints);
    }

    retrieveAllIASClipShareLinksByUser(userEmail: string, domain: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('emailID', '==', userEmail),
        where('domain', '==', domain),
      ];
      return this.queryIASClipBinShareLink(constraints);
    }

    isVideoShareable(clip: Clip) {
      return this.assetService.isVideoShareable(clip);
    }

    createOrUpdateClipBinSharedLink(clipbin: Bin) {

      const clipList$: Observable<DialogSharedLink|ErrorResponse>[] = [];
      this.getAllClips(clipbin.name)
        .pipe(
          filter(clip => this.isVideoShareable(clip)),
          tap(clip => {
            clipList$.push(this.sharedLinks.createLink(clip, {}).pipe(
              map(link => {return {currentClip: clip, currentSharedLink: link };})
            ));
          }),
          finalize(() => {
            this.createBulkClipShareLinks(clipList$,clipbin.title,clipbin.name);
          })
        )
        .subscribe();
    }

    getAllClips(clipbinName: string): Observable<Clip> {
      let count = 0;
      return this.assetService
        .searchClips(clipbinName, undefined, '', LIST_ALL_CLIPS_PAGE_SIZE)
        .pipe(
          // Expand all pages of clips.
          expand((response) => {
            if (isErrorResponse(response)) return EMPTY;
            if (!response?.nextPageToken) return EMPTY;
            // Stop loading more clips if we reach the upper limit.
            if (count >= MAX_CLIPS_COUNT_FOR_BULK) return EMPTY;
            // Trim pageSize to not load more than our limit.
            const pageSize = Math.min(
              LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK - count);
            // Make the next page API call.
            return this.assetService.searchClips(
              clipbinName, undefined, response.nextPageToken,
              pageSize);
          }),
          // Extract clips from each page response.
          concatMap(response => {
            if (isErrorResponse(response)) return [];
            // Local side-effect for failsafe limit.
            count += response.assets.length;
            return response.assets;
          }),
        );
    }

    createBulkClipShareLinks(clipList$: Observable<DialogSharedLink|ErrorResponse>[], binTitle: string, binName: string){
      const encodedBinName = encodeURIComponent(binName);
      forkJoin(clipList$)
        .pipe(
          map(validResponses => validResponses.map(link => {
            const currentLink : DialogSharedLink|ErrorResponse = link;
            if (isErrorResponse(currentLink)) {
              return {
                assetName: '',
                title: '',
                link: '',
                duration: 0
              };
            }
            return {
              assetName:  (currentLink.currentSharedLink as SharedLink).name ,
              title: (currentLink.currentSharedLink as SharedLink).title,
              link: this.sharedLinks.getClipBinAssetLinkHash(currentLink.currentSharedLink as SharedLink),
              duration: (currentLink.currentClip as Clip).duration
            };
          })),

          // Error Handling (Improved)
          catchError(() => {
            return EMPTY;
          }),
          finalize(() => {
            // Any cleanup or final actions can be placed here
          })
        )
        .subscribe(results => {
          const clipBinShareLinkData: IASClipBinShareLinkData = {
            createTime: this.timezone.formatTimestampToISOString(Date.now()),
            clipBinTitle: binTitle,
            clipBinName: encodedBinName,
            clipBinSharedLink: this.sharedLinks.getClipBinLinkUrl(encodedBinName),
            domain: this.origin,
            ttl: 30,
            isActive: true,
            clipSharedLinks: results
          };
          this.persistClipBinSharedLink(encodedBinName,clipBinShareLinkData);
        });
    }

    updateSharedLinkTtl(url: string, newExpirationDays: ClipBinExpirationDaysOption): Observable<string|null> {
      return of (url + newExpirationDays);
    }

    updateClipBinSharedLinkTitle(clipbinName: string, newTitle: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('clipBinName', '==', clipbinName),
        where('domain', '==', this.origin),
        where('isActive', '==', true)
      ];
      this.updatePartialValueInDocuments('ias-clipbin-share-link',constraints,{clipBinTitle:newTitle});
    }

    async persistClipBinSharedLink(encodedBinName: string, clipBinShareLinkData: IASClipBinShareLinkData){
      await this.updateActiveStatusIASClipBinShareLink(encodedBinName,this.origin,false);
      await this.createIASClipBinShareLink(clipBinShareLinkData);
    }

    private queryIASClipBinShareLink(constraints: QueryFieldFilterConstraint[]) {
      return this.queryDocument('ias-clipbin-share-link',constraints)
        .pipe(
          map(documents =>
            documents.map(document => {
              return { ...document.data(), documentId: document.id } as IASClipBinShareLinkData;
            }))
        );
    }

    private queryDocument(collection: string, constraints: QueryFieldFilterConstraint[]) {
      return this.firebaseResolver.queryCollectionWithoutLimitSize(collection, constraints);
    }

    private async updatePartialValueInDocuments(collection: string, constraints: QueryFieldFilterConstraint[], partialValue: object) {
      await this.firebaseResolver.updatePartialValueBatchMode(collection, constraints, partialValue);
    }

    async updateClipbinShareLinkById<T = string>(id:string, field:string, value: T) {
      const ref = doc(this.getDB(), 'ias-clipbin-share-link', id);
      await updateDoc(ref, {
        [field]: value
      });
    }

    async revokeClipbinShareLinkById(id:string) {
      const ref = doc(this.getDB(), 'ias-clipbin-share-link', id);
      await updateDoc(ref, {
        isActive: false,
        updatedTime: this.timezone.formatTimestampToISOString(Date.now()),
        isDeleted: true
      });
    }

  private getDB() {
    return this.firebaseResolver.getFirestore() as Firestore;
  }
}
